import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  NotAuthorized,
  NotFound,
} from '@iarcpsu/emanufac-components/src/components/Error';
import { MainFooter } from '@iarcpsu/emanufac-components/src/components/Footers';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { HomeNavbar } from '@iarcpsu/emanufac-components/src/components/Navbars';
import { MainSidebar } from '@iarcpsu/emanufac-components/src/components/Sidebar';

import * as actions from '../redux/actions';
import accessRight from '../utils/functions/accessRight';
import {
  AddProductStockLot,
  ApproveAddWarehouseStock,
  CostDriverDisplay,
  CreateProduct,
  DecreaseProductStockLot,
  DetailProduct,
  DetailProductStock,
  DetailProductStockLot,
  DetailProductStockLotOfProduct,
  DetailProductTransaction,
  DetailProductType,
  EditManyProduct,
  EditProduct,
  EditProductStockLot,
  EditProductType,
  EditWarehouse,
  HowAIWorkOnCostEstimation,
  ImportExcelProduct,
  Product,
  ProductHistory,
  ProductLotByTime,
  ProductStock,
  ProductType,
  TransfromProductToMaterial,
  Warehouse,
  WarehouseStockRequests,
} from '../views/WMS';

export function WMS() {
  const module = 'WMS';
  const prefix = '/wms';
  const name = 'คลังสินค้า';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const information = useSelector((state) => state.information);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: WMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>คลังสินค้า - {information?.name || 'E-Manufac'}</title>
        </Helmet>
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
          isTaskbarOpen={sidebarOpen}
          setIsTaskbarOpen={setSidebarOpen}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div
          className={`${
            sidebarOpen ? 'lg:ml-64' : ''
          }   min-h-screen  pt-16 px-8 `}
        >
          <div className="py-4">
            <Switch>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/product-stock`}
              />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/product-stock`}>
                <ProductStock title="จัดการสต๊อกสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product-stock/warehouse/:id`}>
                <DetailProductStock title="จัดการสต๊อกสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product-stock/warehouse/`}>
                <DetailProductStock title="จัดการสต๊อกสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product-stock/create/:id`}>
                <AddProductStockLot title="เพิ่มสต๊อกสินค้า" subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/product-stock/withdraw/:id`}>
                <DecreaseProductStockLot
                  title="ลดสต๊อกสินค้า"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/product-stock/create/`}>
                <AddProductStockLot title="เพิ่มสต๊อกสินค้า" subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/product-stock/edit/:id`}>
                <EditProductStockLot title="แก้ไขล็อตสินค้า" subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/product-stock/withdraw/`}>
                <DecreaseProductStockLot
                  title="ลดสต๊อกสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/product-stock/transform-to-material`}
              >
                <TransfromProductToMaterial
                  title="แปลงกลับเป็นวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/product-stock/by-time/`}>
                <ProductLotByTime
                  title="ล็อตสินค้าตามช่วงเวลา"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/product-stock/:id/:warehouseId`}>
                <DetailProductStockLotOfProduct
                  title="ล็อตสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/product-stock/:id/`}>
                <DetailProductStockLotOfProduct
                  title="ล็อตสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/product-stock-lot/:id/`}>
                <DetailProductStockLot
                  title="รายละเอียดล็อตสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/products`}>
                <Product title="จัดการข้อมูลสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product/edit/:id`}>
                <EditProduct title="แก้ไขข้อมูลสินค้า" subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/product/create`}>
                <CreateProduct title="เพิ่มข้อมูลสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product/edit-many/:id`}>
                <EditManyProduct title="แก้ไขข้อมูลสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product/import`}>
                <ImportExcelProduct
                  title="อัพโหลดข้อมูลสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/product/:id`}>
                <DetailProduct title="รายละเอียดข้อมูลสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/history`}>
                <ProductHistory
                  title="ประวัติการจัดการสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/history/detail/:id`}>
                <DetailProductTransaction
                  title="รายละเอียดการจัดการสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/warehouse-stock-request`}>
                <WarehouseStockRequests
                  title="คำขอเพิ่มเข้าคลังสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/warehouse-stock-request/approve/:id`}
              >
                <ApproveAddWarehouseStock
                  title="อนุมัติการเพิ่มเข้าคลังสินค้า"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/cost-driver/how-ai-work/:id/`}>
                <HowAIWorkOnCostEstimation
                  title="การทำงานของ AI ในการประมานการต้นทุน"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/cost-driver/:id/`}>
                <CostDriverDisplay
                  title="รายละเอียดต้นทุนสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/product-types`}>
                <ProductType title="จัดการประเภทสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product-types/edit/:id`}>
                <EditProductType title="แก้ไขประเภทสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product-type/:id`}>
                <DetailProductType
                  title="รายละเอียดประเภทสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/warehouses`}>
                <Warehouse title="จัดการคลังสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/warehouse/edit/:id`}>
                <EditWarehouse title="แก้ไขคลังสินค้า" subtitle={name} />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default WMS;
