import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  PieChart,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { currencyFormatter } from '@/utils/functions';

/**
 * @function DetailDailyMaterialCost
 * @description Display a collections or a list of DetailDailyMaterialCost from database
 */

export default function DetailDailyMaterialCost({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const place = useSelector((state) => state.place);
  const params = useParams();
  const selectedDay = dayjs(params.day);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.materialStockLotAll({
          page: 1,
          size: 100000,
          startDate: selectedDay.startOf('day').format('YYYY-MM-DD'),
          endDate: selectedDay.endOf('day').format('YYYY-MM-DD'),
          stockStatus: '',
        }),
      );
      dispatch(actions.placeAll({ page: 1, size: config.maxFetchSize }));
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const totalCost = _.sum(
    _.map(materialStockLot?.rows, (each) =>
      each.price ? parseFloat(each.price) : 0,
    ),
  );

  const groupByMaterialValues = _.values(
    _.groupBy(materialStockLot?.rows, (each) => each.material?._id),
  );
  const groupByMaterialObject = _.map(groupByMaterialValues, (lot) => {
    const representingMaterial = lot[0].material;
    const groupedCost = _.sum(
      _.map(lot, (eachElement) =>
        eachElement.price ? parseFloat(eachElement.price) : 0,
      ),
    );
    return {
      material: representingMaterial,
      total: groupedCost,
      rows: lot,
    };
  });

  const filterGroupByMaterialObject = _.filter(
    groupByMaterialObject,
    (each) => each.total !== 0,
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialStockLot.isLoading) {
    return <Loading />;
  }
  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="text-lg font-semibold">
                ต้นทุนวัตถุดิบวันที่ {selectedDay.format('D MMMM BBBB')}
              </div>
              <div className="flex flex-wrap justify-between my-2">
                <div className="w-full md:w-1/3 lg:w-2/5">
                  <div>ต้นทุนรวม</div>
                  <div>
                    <span className="text-xl">
                      {currencyFormatter.format(totalCost)}
                    </span>{' '}
                    บาท
                  </div>
                </div>
                {_.map(place?.rows, (eachPlace) => {
                  const currentPlaceLot = _.filter(
                    materialStockLot?.rows,
                    (each) => each.place?._id === eachPlace?._id,
                  );
                  const totalPlaceCost = _.sum(
                    _.map(currentPlaceLot, (each) =>
                      each.price ? parseFloat(each?.price) : 0,
                    ),
                  );
                  return (
                    <div className="w-1/2 md:w:1/3 lg:w-1/5">
                      <div>{eachPlace?.name}</div>
                      <div>
                        <span className="text-xl">
                          {currencyFormatter.format(totalPlaceCost)}
                        </span>{' '}
                        บาท
                      </div>
                    </div>
                  );
                })}
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-1/2">
                  <PieChart
                    series={_.map(
                      filterGroupByMaterialObject,
                      (each) => each.total,
                    )}
                    labels={_.map(
                      filterGroupByMaterialObject,
                      (each) => each.material?.name,
                    )}
                  />
                </div>
                <div className="w-full lg:w-1/2 ">
                  {_.map(filterGroupByMaterialObject, (eachMaterial, index) => {
                    const costedRows = _.filter(
                      eachMaterial.rows,
                      (eachRow) => eachRow.price && eachRow?.price !== 0,
                    );
                    const totalQuantity = _.sumBy(costedRows, 'quantity');
                    return (
                      <div key={index} className="my-2">
                        <div className=" font-semibold">
                          {eachMaterial.material?.name}
                        </div>
                        <div>
                          <span className="">
                            {currencyFormatter.format(eachMaterial.total)}
                          </span>{' '}
                          บาท
                        </div>
                        <div>
                          รวม {currencyFormatter.format(totalQuantity)}{' '}
                          {eachMaterial?.material?.unit} (เฉลี่ย{' '}
                          {currencyFormatter.format(
                            eachMaterial.total / totalQuantity,
                          )}{' '}
                          บาทต่อหน่วย)
                        </div>
                        <div className="flex flex-wrap">
                          {_.map(costedRows, (eachRow, materialIndex) => (
                            <div
                              key={materialIndex}
                              className="my-2 w-full lg:w-1/2"
                            >
                              <div className="font-semibold">
                                เลขอ้างอิงล็อต: {eachRow?.running_number}
                              </div>
                              <li>{eachRow?.place?.name} </li>
                              <li>
                                จำนวน: {eachRow?.quantity}{' '}
                                {eachRow?.material?.unit}
                              </li>{' '}
                              <li>
                                ต้นทุนต่อหน่วย:{' '}
                                {currencyFormatter.format(
                                  eachRow?.price / eachRow?.quantity,
                                )}{' '}
                                บาท
                              </li>
                              <li>
                                ราคา: {currencyFormatter.format(eachRow?.price)}{' '}
                                บาท
                              </li>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailDailyMaterialCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailDailyMaterialCost.defaultProps = {
  title: '',
  subtitle: '',
};
