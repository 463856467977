import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DaySpotAndRangePicker,
  Error,
  Loading,
  ManyProductStockLotTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Paper,
  TablePagination,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function ProductLotByTime
 * @description Display a collections or a list of ProductLotByTime from database
 */

export default function ProductLotByTime({ title, subtitle }) {
  const dispatch = useDispatch();
  const productStockLot = useSelector((state) => state.productStockLot);
  const information = useSelector((state) => state.information);
  const wmsSetting = information?.setting?.wms;
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [startDate, setStartDate] = useState(dayjs().toDate());
  const [endDate, setEndDate] = useState(dayjs().toDate());
  const [showCost, setIsShowCost] = useState(false);
  const [total, setTotal] = useState(0);
  const [isShowNet, setIsShowNet] = useState(false);
  const [isShowAmount, setIsShowAmount] = useState(false);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.productStockLotAll({
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
          page,
          size,
        }),
      );
    } catch (error) {
      dispatch(actions.productStockLotError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, startDate, endDate]);

  useEffect(() => {
    setTotal(productStockLot?.total);

    return () => {};
  }, [productStockLot]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (productStockLot.isLoading) {
    return <Loading />;
  }

  if (!productStockLot.isLoading && productStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <Paper>
            <div className="flex flex-wrap py-4 px-2">
              <div className="w-full lg:w-1/3"></div>
              <div className="w-full lg:w-2/3 px-2">
                <DaySpotAndRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  state="date"
                  flex
                />
              </div>
            </div>
          </Paper>
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div>ตัวเลือกเพิ่มเติม</div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-1/2 flex flex-col">
                  <FormControlLabel
                    label="แสดงยอดคงเหลือ"
                    control={
                      <Checkbox
                        value={isShowAmount}
                        onChange={(e) => setIsShowAmount(e.target.checked)}
                      />
                    }
                  />
                  {wmsSetting?.display?.cost_price && (
                    <FormControlLabel
                      label="แสดงต้นทุน"
                      control={
                        <Checkbox
                          value={showCost}
                          onChange={(e) => setIsShowCost(e.target.checked)}
                        />
                      }
                    />
                  )}{' '}
                </div>{' '}
                <div className="w-full lg:w-1/2 flex flex-col">
                  {wmsSetting?.productAttribute?.net && (
                    <FormControlLabel
                      label="แสดงปริมาตรสุทธิ"
                      control={
                        <Checkbox
                          value={isShowNet}
                          onChange={(e) => setIsShowNet(e.target.checked)}
                        />
                      }
                    />
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <ManyProductStockLotTable
            productStockLots={productStockLot.rows}
            showAmount={isShowAmount}
            showNet={isShowNet}
            showProductionDate={wmsSetting?.display?.production_date}
            showExpirationDate={wmsSetting?.display?.expiration_date}
            showCustomer
            showPrice={wmsSetting?.display?.price}
            showCost={showCost}
          />
        </div>
        <div className="my-2">
          <TablePagination
            component="div"
            count={total}
            page={page - 1}
            onPageChange={(event, newValue) => {
              setPage(newValue + 1);
            }}
            rowsPerPage={size}
            onRowsPerPageChange={(event) => {
              setSize(event.target.value);
              setPage(1);
            }}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

ProductLotByTime.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductLotByTime.defaultProps = {
  title: '',
  subtitle: '',
};
