import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Switch } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { findParentAndChildLot } from '@/utils/functions';

import MachineDrivenOnEachParent from './MachineDrivenOnEachParent';

const MachineTimeDrivenBox = ({ previousLotList, defaultSplittedParent }) => {
  const [isSplitedParentSource, setIsSplitedParentSource] = useState(
    defaultSplittedParent,
  );
  const parentWithChildLots = findParentAndChildLot(previousLotList);
  return (
    <div>
      <div className="flex justify-between">
        <h3 className="font-semibold my-1">ต้นทุนจากเครื่องจักร</h3>
        <div>
          <Link to="/setting/pams/inventory-workflow">
            <Button startIcon={<i className="fas fa-cog"></i>}>
              ปรับการคิดราคา
            </Button>
          </Link>
        </div>
      </div>
      <div className="p-2">
        <div className="">
          <div className="flex items-center gap-2">
            รวมทุกๆ ต้นทาง
            <Switch
              checked={isSplitedParentSource}
              onChange={(e) => setIsSplitedParentSource(e.target.checked)}
            />
            แยกล็อตต้นทาง
          </div>
        </div>
        {isSplitedParentSource ? (
          <div>
            {_.map(parentWithChildLots, (each, index) => (
              <div key={index}>
                <div className="font-semibold">ต้นทางที่ {index + 1}</div>
                <MachineDrivenOnEachParent
                  key={index}
                  previousLotList={[each, ...(each?.child_lot || [])]}
                />
              </div>
            ))}
          </div>
        ) : (
          <div>
            <MachineDrivenOnEachParent previousLotList={previousLotList} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MachineTimeDrivenBox;

MachineTimeDrivenBox.propTypes = {
  previousLotList: PropTypes.arrayOf(PropTypes.object),
  defaultSplittedParent: PropTypes.bool,
};
