import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  DesktopDatepicker,
  EmployeeAutoComplete,
  Error,
  Loading,
  MultiUnitProdOrMatBox,
  ProdOrMatQuantityInput,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function EditProductStockLot
 * @description Edit Information of Product stock lot
 */

export default function EditProductStockLot({ title, subtitle }) {
  const dispatch = useDispatch();
  const productStockLot = useSelector((state) => state.productStockLot);

  const employee = useSelector((state) => state.employee);
  const customer = useSelector((state) => state.customer);
  const history = useHistory();
  const params = useParams();
  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      cost_price: productStockLot?.cost_price,
      cost_price_per_unit:
        productStockLot?.cost_price / productStockLot?.quantity,
      use_unit_cost: true,
    },
  });

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.productStockLotGet(params.id));
      dispatch(
        actions.employeeAll({
          name: '',
          page: 1,
          size: config.maxFetchSize,
        }),
      );
      dispatch(
        actions.customerAll({
          name: '',
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      dispatch(actions.productStockLotError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (productStockLot?._id && !productStockLot?.rows) {
      setValue('cost_price', productStockLot?.cost_price);
      setValue(
        'cost_price_per_unit',
        productStockLot?.cost_price / productStockLot?.quantity,
      );
    }

    return () => {};
  }, [productStockLot]);

  const handleEdit = async (data) => {
    try {
      dispatch(
        actions.productStockLotPut(params.id, {
          ...data,
          consignee_name: `${data?.employee?.firstname} ${
            data?.employee?.lastname || ''
          }`,
        }),
      );
      Swal.fire({
        title: 'แก้ไขสำเร็จ',
        icon: 'success',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        title: 'แก้ไขไม่สำเร็จ',
        text: error?.message,
        icon: 'error',
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (productStockLot.isLoading || productStockLot?.rows) {
    return <Loading />;
  }

  if (
    !productStockLot.isLoading &&
    productStockLot.isCompleted &&
    !productStockLot?.rows
  ) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleEdit)}>
            <Card>
              <CardContent>
                <hr className="my-2" />
                <div className="p-2">
                  <div className="font-semibold">สินค้า</div>
                  <div className="text-lg mb-2">
                    {productStockLot?.product?.type_code}{' '}
                    {productStockLot?.product?.name}
                  </div>
                  <div className="flex flex-wrap justify-between items-center">
                    <div className="w-full px-1 py-2">
                      {!_.isEmpty(customer?.rows) && (
                        <Controller
                          name={'customer'}
                          control={control}
                          defaultValue={productStockLot?.customer}
                          render={({ field }) => (
                            <Autocomplete
                              size={'small'}
                              {...field}
                              onChange={(e, newValue) => {
                                field.onChange(newValue);
                              }}
                              options={customer?.rows}
                              getOptionLabel={(option) =>
                                `${option?.type_code || ''} | ${
                                  option?.name || ''
                                }`
                              }
                              renderInput={(inputParam) => (
                                <TextField {...inputParam} label="ลูกค้า" />
                              )}
                            />
                          )}
                        />
                      )}
                    </div>
                    <div className="w-full px-1 my-2">
                      <Controller
                        name={'inventory_date'}
                        control={control}
                        defaultValue={productStockLot?.inventory_date}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <DesktopDatepicker
                            {...field}
                            label="วันที่เข้าคลัง *"
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </div>
                    <div className="w-full md:w-1/2"> ปริมาณทั้งหมด </div>
                    <div className="flex gap-2 w-full md:w-1/2 justify-end">
                      <MultiUnitProdOrMatBox
                        foundMaterial={productStockLot?.product}
                        quantity={productStockLot?.quantity}
                      />
                    </div>
                    <div className="w-full md:w-1/2 my-2">ปริมาณใหม่</div>
                    <div className="w-full md:w-1/2 justify-end my-2">
                      <ProdOrMatQuantityInput
                        controllerName="quantity"
                        artificialControllerName="quantity_artificial"
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        defaultValue={productStockLot?.quantity}
                        selectedProduct={productStockLot?.product}
                        selectedUnitControllerName="quantity_selected_unit"
                      />
                    </div>{' '}
                    <div className="w-full md:w-1/2"> คงเหลือเดิม </div>
                    <div className="flex gap-2 w-full md:w-1/2 justify-end">
                      <MultiUnitProdOrMatBox
                        foundMaterial={productStockLot?.material}
                        quantity={productStockLot?.amount}
                      />
                    </div>
                    <div className="w-full md:w-1/2 my-2">คงเหลือใหม่</div>
                    <div className="w-full md:w-1/2 justify-end my-2">
                      <ProdOrMatQuantityInput
                        controllerName="amount"
                        artificialControllerName="amount_artificial"
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        defaultValue={productStockLot?.amount}
                        selectedProduct={productStockLot?.material}
                        selectedUnitControllerName="amount_selected_unit"
                      />
                    </div>
                  </div>
                  {parseFloat(watch('quantity')) <
                    parseFloat(watch('amount')) && (
                    <Alert severity="error">
                      ปริมาณทั้งหมดไม่ควรน้อยกว่าคงเหลือ
                    </Alert>
                  )}
                </div>
                <hr className="my-2" />
                <div className="flex flex-wrap justify-between items-center">
                  <div className="w-full md:w-1/2"> ต้นทุนต่อหน่วย </div>
                  <div className="flex flex-wrap gap-2 w-full md:w-1/2 justify-end">
                    <Controller
                      control={control}
                      name="cost_price_per_unit"
                      defaultValue={
                        productStockLot?.cost_price / productStockLot?.quantity
                      }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ต้นทุนต่อหน่วย"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            field.onChange(e);
                            setValue(
                              'cost_price',
                              parseFloat(e.target.value) *
                                parseFloat(watch('quantity')),
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full md:w-1/2"> ต้นทุนทั้งล็อต </div>
                  <div className="flex flex-wrap gap-2 w-full md:w-1/2 justify-end">
                    <Controller
                      control={control}
                      name="cost_price"
                      defaultValue={productStockLot?.cost_price}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ต้นทุนทั้งล็อต (บาท)"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            field.onChange(e);
                            setValue(
                              'cost_price_per_unit',
                              parseFloat(e.target.value) /
                                parseFloat(watch('quantity')),
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full md:w-1/2 my-1">
                    ผู้ที่ดำเนินการกับล็อต / ผู้รับวัตถุดิบ
                  </div>
                  <div className="w-full md:w-1/2 my-1 justify-end">
                    {!_.isEmpty(employee?.rows) && (
                      <Controller
                        control={control}
                        name="employee"
                        defaultValue={productStockLot?.employee}
                        render={({ field }) => (
                          <EmployeeAutoComplete
                            employeeRows={employee?.rows}
                            field={field}
                            label="ผู้ดำเนินการ"
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>{' '}
            <div className="flex justify-end my-1">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditProductStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProductStockLot.defaultProps = {
  title: '',
  subtitle: '',
};
