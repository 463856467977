import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';

import api from '../../../configs/api';

dayjs.extend(durationPlugin);
dayjs.extend(relativeTime);

/**
 * @function getMachineUnitCost
 * @description ยิง API ไปยัง Backend เเพื่อดูว่าค่าเช่าเครื่องจักรนั้น
 * มีค่าเป็นเท่าไหร่ โดยยิงไปที่ machine จากนั้นดูว่าเป็นรายเดือน หรือ รายวัน
 * หารออกมาเป็นค่าเช่าต่อชั่วโมง
 * @param {String} selectedMachine ข้อมูลเครื่องจักรที่เลือก
 * @param {String} selectedUnit ข้อมูลหน่วยที่เลือก
 */
export const getHourMachineUnitCost = (machine) => {
  try {
    const unitCost =
      machine?.attribute?.price /
      (machine?.attribute?.service_life *
        12 *
        machine?.attribute?.day_per_month *
        machine?.attribute?.hour_per_day);

    return unitCost;
  } catch (error) {
    console.error('Cannot Calculate machine unit cost', error);
    return 0;
  }
};

export const getMinuteMachineUnitCost = (machine) => {
  try {
    const unitCost =
      machine?.attribute?.price /
      (machine?.attribute?.service_life *
        12 *
        machine?.attribute?.day_per_month *
        machine?.attribute?.hour_per_day *
        60);

    return unitCost;
  } catch (error) {
    console.error('Cannot Calculate machine unit cost', error);
    return 0;
  }
};

export const getHourMachineUnitCostWithAPI = async (machineID) => {
  try {
    const { data: machine } = await api.get(
      `${process.env.REACT_APP_API_URL}/machine/${machineID}`,
    );
    const unitCost =
      machine?.attribute?.price /
      (machine?.attribute?.service_life *
        12 *
        machine?.attribute?.day_per_month *
        machine?.attribute?.hour_per_day);

    return unitCost;
  } catch (error) {
    console.error('Cannot Calculate machine unit cost', error);
    return 0;
  }
};

export const getMinuteMachineUnitCostWithAPI = async (machineID) => {
  try {
    const { data: machine } = await api.get(
      `${process.env.REACT_APP_API_URL}/machine/${machineID}`,
    );
    const unitCost =
      machine?.attribute?.price /
      (machine?.attribute?.service_life *
        12 *
        machine?.attribute?.day_per_month *
        machine?.attribute?.hour_per_day *
        60);

    return unitCost;
  } catch (error) {
    console.error('Cannot Calculate machine unit cost', error);
    return 0;
  }
};

export default getHourMachineUnitCost;
