import React, { useContext, useEffect, useState } from 'react';
import {
  ArrowDown,
  ArrowUp,
  CornerUpRight,
  Grid as GridIcon,
  List as ListIcon,
  RefreshCw,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  BackButton,
  CardListProductOrMaterial,
  Error,
  ExportExcelContainer,
  LoadingLinear,
  MaterialStockLotTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { REPORT_SUB_PLACE_IN_WAREHOUSE } from '@iarcpsu/emanufac-constant';
import {
  Autocomplete,
  Button,
  Card,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';

import { app as appConfig } from '../../configs';
import * as actions from '../../redux/actions';
import { DISPLAY_TYPE, MATERIAL_STOCK_STATUS } from '../../utils/constants';
import { MaterialUtil } from '../../utils/modelutils';

function DetailMaterialStock({ title, subtitle }) {
  const dispatch = useDispatch();
  const { findWordInPlace } = useContext(WordLocalizeContext);
  const { id } = useParams();
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const materialType = useSelector((state) => state.materialType);
  const information = useSelector((state) => state.information);
  const pdfReport = useSelector((state) => state.pdfReport);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [selectedView, setSelectedView] = useState(
    window?.localStorage?.getItem('STOCK_DISPLAY_TYPE') ||
      DISPLAY_TYPE.CARD.status_code,
  );
  const [useRedis, setIsUseRedis] = useState(true);
  const [selectedMaterialType, setSelectedMaterialType] = useState();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      dispatch(actions.placeGet(id));
    }
    dispatch(actions.materialTypeAll({ size: appConfig.maxFetchSize }));
    dispatch(actions.pdfReportAll({ warehouse: id }));

    return () => {};
  }, []);

  useEffect(() => {
    dispatch(actions.materialReset());
    dispatch(
      actions.materialAll({
        name,
        size,
        page,
        place: id || '',
        fetchStockLot: true,
        selectMaterialType: selectedMaterialType?._id,
        stockStatus: MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
        forceWithoutCache: useRedis ? '' : true,
        placeRestricNotify: place?.inventory_restriction || '',
      }),
    );
    return () => {};
  }, [name, page, size, selectedMaterialType, useRedis, place]);

  useEffect(() => {
    setTotal(material?.total);
    return () => {};
  }, [material]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => (
    <ViewTitle title={`${title} ${place?.name || ''} `} subtitle={subtitle} />
  );

  const handlePushToDetailMaterialStock = async (materialId) => {
    history.push(`/ims/material-stock/${materialId}/${id || ''}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSetSelectView = (tempSelectedView) => {
    if (tempSelectedView !== null) {
      if (tempSelectedView === DISPLAY_TYPE.CARD.status_code) {
        setSize(20);
        setPage(1);
      }
      setSelectedView(tempSelectedView);
    }
  };

  const materialQuery = MaterialUtil.query({ id, selectedMaterialType });

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-1">
      <Button
        variant="contained"
        startIcon={<ArrowDown size={16} />}
        onClick={() => history.push(`/ims/material-stock/create/${id || ''}`)}
      >
        เพิ่มวัตถุดิบ
      </Button>
      <Button
        variant="contained"
        startIcon={<ArrowUp size={16} />}
        color="error"
        onClick={() => history.push(`/ims/material-stock/withdraw/${id || ''}`)}
      >
        เบิกวัตถุดิบ
      </Button>
      {place?.place_action?.add_to_product_warehouse && (
        <Button
          variant="contained"
          color="success"
          startIcon={<i className="fas fa-layer-group" />}
          onClick={() => {
            history.push(`/ims/material-stock-to-product?place=${id || ''}`);
          }}
        >
          แปลงเป็นสินค้า
        </Button>
      )}{' '}
      {information?.setting?.ims?.operationDisplay?.displayByMaterialSource && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<i className="fas fa-layer-group" />}
          onClick={() => {
            history.push(
              `/ims/material-stock/group?by=material-source&place=${id || ''}`,
            );
          }}
        >
          {findWordInPlace('group_by_source') || 'แสดงเป็นกลุ่มตามที่มา'}
        </Button>
      )}{' '}
      <Button
        variant="contained"
        color="teal"
        startIcon={<i className="fas fa-store-slash" />}
        onClick={() => {
          history.push(
            `/ims/material-stock/group?by=material-source&place=${
              id || ''
            }&mistakeplace=true`,
          );
        }}
      >
        {findWordInPlace('stranger_material') || 'วัตถุดิบไม่ตรงคลัง'}
      </Button>
      <Button
        variant="contained"
        color="warning"
        startIcon={<CornerUpRight size={16} />}
        onClick={() => {
          history.push(`/ims/move/${id}`);
        }}
      >
        ย้ายวัตถุดิบ
      </Button>{' '}
      {information?.setting?.ims?.allowMaterialTransform && (
        <Button
          variant="contained"
          color="info"
          startIcon={<i className="fas fa-magic"></i>}
          onClick={() => {
            history.push(`/ims/transform/${id}`);
          }}
        >
          แปลงวัตถุดิบ
        </Button>
      )}{' '}
      <Button
        variant="contained"
        color="teal"
        startIcon={<RefreshCw size={16} />}
        onClick={() => {
          setIsUseRedis(false);
        }}
      >
        รีเฟรช
      </Button>{' '}
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-wrap justify-around">
        <div className="w-full md:w-1/2 my-1">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full lg:w-3/12 lg:px-2 my-1">
          {!_.isEmpty(materialType?.rows) && (
            <Autocomplete
              value={selectedMaterialType}
              disablePortal
              options={materialType?.rows}
              size="small"
              placeholder="ประเภทวัตถุดิบ"
              onChange={(e, newValue) => {
                setSelectedMaterialType(newValue);
              }}
              className="z-40"
              // prettier-ignore
              getOptionLabel={(option) => `${option?.name || ''}`
        }
              renderInput={(params) => (
                <TextField label="ประเภทวัตถุดิบ" {...params} />
              )}
            />
          )}
        </div>
        <div className="lg:w-2/12 flex justify-end">
          <ExportExcelContainer
            columnList={MaterialUtil.columns}
            currentData={material?.rows}
            dataAPIEndPoint="material"
            dataQuery={materialQuery}
            sheetName="MaterialStock"
          />
        </div>
        <div className="lg:w-1/12 justify-end flex">
          <ToggleButtonGroup
            value={selectedView}
            exclusive
            size="small"
            onChange={(event, value) => {
              window?.localStorage?.setItem('STOCK_DISPLAY_TYPE', value);
              handleSetSelectView(value);
            }}
          >
            <ToggleButton value={DISPLAY_TYPE.TABLE.status_code}>
              <ListIcon size="16" />
            </ToggleButton>
            <ToggleButton value={DISPLAY_TYPE.CARD.status_code}>
              <GridIcon size="16" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <MaterialStockLotTable
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handlePushToDetail={handlePushToDetailMaterialStock}
        payload={material}
        title="วัตถุดิบ"
        page={page}
        size={size}
        total={total}
      />
    </div>
  );

  const renderCard = () => (
    <div className="my-2">
      <CardListProductOrMaterial
        payload={material}
        page={page}
        size={size}
        total={total}
        setSize={setSize}
        handlePushToStock={handlePushToDetailMaterialStock}
      />
    </div>
  );

  const renderMaterial = () => (
    <div>
      {selectedView === DISPLAY_TYPE.TABLE.status_code
        ? renderTable()
        : renderCard()}
    </div>
  );

  if (!material?.isLoading && !material?.isCompleted) {
    return <Error message={material?.message} />;
  }

  return (
    <div>
      {renderTitle()}
      <div className="flex justify-between">
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div className="flex flex-row justify-end pb-4">
          {_.map(
            _.filter(
              pdfReport?.rows,
              (each) =>
                each?.sub_place_in_warehose ===
                REPORT_SUB_PLACE_IN_WAREHOUSE.WAREHOUSE_STOCK.status_code,
            ),
            (pdf) => (
              <Button> {pdf.printing_label || pdf?.name} </Button>
            ),
          )}
        </div>
      </div>{' '}
      <div>{renderAddButton()}</div>
      {renderSearch()}
      {material?.isLoading ? (
        <div>
          <LoadingLinear label="กำลังดาวน์โหลดข้อมูล" />
        </div>
      ) : (
        renderMaterial()
      )}
    </div>
  );
}

DetailMaterialStock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterialStock.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailMaterialStock;
