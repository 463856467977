/* eslint-disable no-confusing-arrow */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { setLang } from '@antv/s2';
import { SheetComponent } from '@antv/s2-react';
import { PRODUCT_STOCK_STATUS } from '@iarcpsu/emanufac-constant';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ManyProductStockLotTable = ({
  productStockLots,
  showCustomer,
  showAmount,
  showCost,
  showPrice,
  showProductionDate,
  showExpirationDate,
  showNet,
  groupedBy,
  showLotNumber,
}) => {
  setLang('en_US');
  const rowList = ['product_type_code', 'product_name'];
  const valueList = ['quantity'];
  const extraMeta = [];
  const history = useHistory();

  const payload = _.map(productStockLots, (each) => ({
    lot_id: each?._id,
    running_number: each?.running_number,
    lot_number: each?.lot_number,
    product_id: each?.product?._id,
    product_type_code: each?.product?.type_code || '-',
    product_type: each?.product?.product_type?.name,
    product_name: each?.product?.name,
    customer: each?.customer?.name || '-',
    quantity: each?.quantity,
    amount: each?.amount,
    cost_unit_price: each?.cost_price / each?.quantity,
    cost_price: each?.cost_price,
    unit: each?.product?.unit,
    price: each?.price,
    status: each?.status,
    inventory_date: each?.inventory_date,
    production_date: each?.production_date,
    expiration_date: each?.expiration_date,
    consignee_name: each?.consignee_name,
    detail: `/wms/product-stock-lot/${each?._id}`,
    net: each?.product?.net,
    net_quantity: each?.product?.net * each?.quantity,
    net_amount: each?.product?.net * each?.amount,
    unit_net: each?.product?.unit_net,
  }));

  if (showCustomer) {
    rowList.push('customer');
  }
  rowList.push('running_number');
  rowList.push('inventory_date');

  if (showAmount) {
    valueList.push('amount');
  }
  valueList.push('unit');

  if (showCost) {
    extraMeta.push({
      field: 'cost_unit_price',
      name: 'ต้นทุนต่อหน่วย',
    });

    extraMeta.push({
      field: 'cost_price',
      name: 'ต้นทุนรวม',
    });

    valueList.push('cost_unit_price');
    valueList.push('cost_price');
  }

  if (showPrice) {
    extraMeta.push({
      field: 'price',
      name: 'ราคาขาย',
    });
  }

  if (showProductionDate) {
    extraMeta.push({
      field: 'production_date',
      name: 'วันผลิต',
      formatter: (data) =>
        data ? dayjs(data).format('D MMM BBBB HH:mm') : '-',
    });
  }

  if (showExpirationDate) {
    extraMeta.push({
      field: 'expiration_date',
      name: 'วันหมดอายุ',
      formatter: (data) =>
        data ? dayjs(data).format('D MMM BBBB HH:mm') : '-',
    });
  }

  rowList.push('status');
  rowList.push('detail');

  if (showNet) {
    extraMeta.push({
      field: 'net',
      name: 'ปริมาตรสุทธิ',
    });
    extraMeta.push({
      field: 'net_quantity',
      name: 'รวมปริมาตร',
    });
    extraMeta.push({
      field: 'unit_net',
      name: 'หน่วยปริมาตร',
    });
    valueList.push('net');
    valueList.push('net_quantity');
    if (showAmount) {
      valueList.push('net_amount');
    }
    valueList.push('unit_net');
  }

  /** @type {import('@antv/s2').S2DataConfig} */
  const config = {
    data: payload,
    fields: {
      rows: rowList,
      //  columns: columnList,
      values: valueList,
      valueInCols: true,
    },
    meta: [
      {
        field: 'product_type_code',
        name: 'รหัสสินค้า',
      },
      {
        field: 'product_name',
        name: 'ชื่อสินค้า',
      },
      {
        field: 'running_number',
        name: 'เลขอ้างอิงล็อต',
      },
      {
        field: 'customer',
        name: 'ลูกค้า',
      },
      {
        field: 'status',
        name: 'สถานะ',
        formatter: (data) => PRODUCT_STOCK_STATUS[data]?.description || '-',
      },
      {
        field: 'quantity',
        name: 'ปริมาณ',
        formatter: (data) => {
          if (data === null) {
            return 0;
          }
          if (_.isNaN(data)) {
            return 0;
          }

          if (data % 0 === 0) {
            return data;
          }

          return parseFloat(data).toFixed(2);
        },
      },
      {
        field: 'amount',
        name: 'คงเหลือ',
        formatter: (data) => {
          if (data === null) {
            return 0;
          }
          if (_.isNaN(data)) {
            return 0;
          }

          if (data % 0 === 0) {
            return data;
          }

          return parseFloat(data).toFixed(2);
        },
      },
      {
        field: 'inventory_date',
        name: 'วันที่เข้าคลัง',
        // eslint-disable-next-line no-confusing-arrow
        formatter: (data) =>
          data ? dayjs(data).format('D MMM BBBB HH:mm') : '-',
      },
      {
        field: 'unit',
        name: 'หน่วย',
      },
      {
        field: 'detail',
        name: 'รายละเอียด',
        formatter: (value) => {
          if (value === 'null') {
            return null;
          }
          if (value !== '$$empty_field_value$$') {
            return 'รายละเอียด';
          }
          return null;
        },
      },
      ...extraMeta,
    ],
    width: 800,
    sortParams: [
      //   {
      //     sortFieldId: 'material_type',
      //     sortMethod: 'ASC',
      //   },
      //   {
      //     sortFieldId: 'product_name',
      //     sortMethod: 'ASC',
      //   },
    ],
  };

  const calcFunc = (query, data) => {
    const quantityList = _.map(data, (each) => each?.quantity);
    return _.sum(quantityList);
  };

  return (
    <div>
      <SheetComponent
        dataCfg={config}
        sheetType="pivot"
        themeCfg={{
          name: 'gray',
          theme: {
            dataCell: {
              bolderText: {
                fontSize: 14,
              },
              text: {
                fontSize: 14,
              },
            },
            colCell: {
              text: {
                fontSize: 14,
              },
              bolderText: {
                fontSize: 14,
              },
            },
            headerCell: {
              text: {
                fontSize: 14,
              },
              bolderText: {
                fontSize: 14,
              },
            },
            rowCell: {
              text: {
                fontSize: 14,
              },
              bolderText: {
                fontSize: 14,
              },
            },
            mergedCell: {
              text: {
                fontSize: 14,
              },
              bolderText: {
                fontSize: 14,
              },
            },
            cornerCell: {
              text: {
                fontSize: 14,
              },
              bolderText: {
                fontSize: 14,
              },
            },
          },
        }}
        options={{
          tooltip: {
            enable: true,
          },
          interaction: {
            selectedCellsSpotlight: true,
            hoverHighlight: true,
            copy: { enable: true },
            linkFields: ['detail'],
          },
          totals: {
            row: {
              calcTotals: {
                calcFunc,
              },
              subLabel: 'รวม',
              showGrandTotals: true,
              label: 'รวม',
            },
          },
        }}
        onLinkFieldJump={(jump) => {
          if (jump?.key === 'detail') {
            const actionLink = jump?.record?.detail;
            console.log('Action Link', actionLink);
            history.push(actionLink);
          } else if (jump?.key === 'action') {
            const actionLink = jump?.record?.action;
            history.push(actionLink);
          }
        }}
        header={{
          className: 'flex justify-end text-xs',
          title: 'Export',
          exportCfg: {
            open: true,
            className: 'flex',
          },
        }}
        adaptive
      />
    </div>
  );
};

export default ManyProductStockLotTable;

ManyProductStockLotTable.propTypes = {
  productStockLots: PropTypes.arrayOf(PropTypes.object),
  showPreferProduct: PropTypes.bool,
  showCustomer: PropTypes.bool,
  showAmount: PropTypes.bool,
  groupedBy: PropTypes.string,
  showCost: PropTypes.bool,
  showPrice: PropTypes.bool,
  showProductionDate: PropTypes.bool,
  showExpirationDate: PropTypes.bool,
  showNet: PropTypes.bool,
};
