import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';

import { DesktopDatepicker, DesktopDateTimepicker } from '../../DatePicker';
import EmployeeAutoComplete from '../../Inputs/EmployeeAutoComplete';

const LotInformationForm = ({
  control,
  defaultValue,
  employee,
  departments,
  me,
  machine,
  allowShowMachineInput,
  allowGroupEmployeeInput,
}) => {
  const { findWord } = useContext(WordLocalizeContext);
  const information = useSelector((state) => state.information);

  const filterGroupEmployee = _.filter(
    employee?.rows,
    (each) => each?.is_virtual_group_employee,
  );

  return (
    <div className="w-full flex flex-wrap">
      {!defaultValue && !_.isEmpty(employee?.rows) && (
        <div className="w-full  lg:w-1/2 px-1">
          <Controller
            name="employee"
            control={control}
            defaultValue={me}
            render={({ field }) => (
              <EmployeeAutoComplete
                field={field}
                employeeRows={employee?.rows}
                label="ผู้ดำเนินการ"
              />
            )}
          />
        </div>
      )}{' '}
      <div className="w-full lg:w-1/2  px-1 mb-2">
        <Controller
          name={'receipt_date'}
          control={control}
          defaultValue={defaultValue?.receipt_date || new Date()}
          rules={{ required: true }}
          // eslint-disable-next-line no-confusing-arrow
          render={({ field }) =>
            information?.setting?.ims?.showTime ? (
              <DesktopDateTimepicker
                {...field}
                label={findWord('receipt_date') || 'วันรับวัตถุดิบ'}
                fullWidth={true}
                size="small"
              />
            ) : (
              <DesktopDatepicker
                {...field}
                label={findWord('receipt_date') || 'วันรับวัตถุดิบ'}
                fullWidth
                size="small"
              />
            )
          }
        />
      </div>
      {allowShowMachineInput && information?.setting?.ims?.display?.machine && (
        <div className="w-full lg:w-1/2 px-1 mb-2">
          {!_.isEmpty(machine?.rows) && (
            <Controller
              name={'machine'}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={machine?.rows}
                  size="small"
                  placeholder="เลือกเครื่องจักร"
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name || ''}`
              }
                  renderInput={(params) => (
                    <TextField label="เลือกเครื่องจักร" {...params} />
                  )}
                />
              )}
            />
          )}
        </div>
      )}
      {!defaultValue && (
        <div className="w-full  lg:w-1/2 px-1 ">
          <Controller
            name={'order_department'}
            control={control}
            defaultValue={me ? me?.department?.id : ''}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
                <InputLabel id="type" size={'small'}>
                  แผนกผู้สั่งซื้อ
                </InputLabel>
                <Select {...field} label="คู่ค้า" size={'small'} fullWidth>
                  {_.size(departments) ? (
                    _.map(departments, (_department) => (
                      <MenuItem key={_department.id} value={_department.id}>
                        {_department.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>
                      <MenuItem disabled value="">
                        <em>ไม่มีข้อมูล</em>
                      </MenuItem>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          />
        </div>
      )}
      <div className="w-full  lg:w-1/2  px-1 ">
        <Controller
          name={'remark'}
          control={control}
          defaultValue={defaultValue?.remark || ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              size={'small'}
              multiline={true}
              rows={3}
            />
          )}
        />
      </div>
      <div className="flex flex-wrap w-full">
        {allowGroupEmployeeInput &&
          information?.setting?.ims?.showGroupEmployeeInputInSpreadWay && (
            <div className="w-full lg:w-2/3 px-1">
              <div className="my-2 pb-2">พนักงานที่ร่วมทำงาน</div>
              {_.map(filterGroupEmployee, (each, index) => (
                <div className="flex gap-2 w-full py-2">
                  <div className="w-2/3">
                    <Controller
                      key={index}
                      name={`another_employees[${index}].employee`}
                      control={control}
                      defaultValue={each}
                      render={({ field }) => (
                        <EmployeeAutoComplete
                          field={field}
                          employeeRows={employee?.rows}
                          label="ผู้ดำเนินการ"
                        />
                      )}
                    />
                  </div>
                  <div className="w-1/3">
                    <Controller
                      key={index}
                      name={`another_employees[${index}].amount`}
                      control={control}
                      defaultValue={0}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="จำนวน"
                          fullWidth
                          size={'small'}
                          type="number"
                        />
                      )}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default LotInformationForm;

LotInformationForm.propTypes = {
  control: PropTypes.object,
  defaultValue: PropTypes.object,
  employee: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  machine: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  departments: PropTypes.arrayOf(PropTypes.object),
  me: PropTypes.object,
  allowShowMachineInput: PropTypes.bool,
  allowGroupEmployeeInput: PropTypes.bool,
};
