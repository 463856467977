import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  EmployeeTimeDrivenBox,
  Error,
  Loading,
  LoadingLinear,
  MachineTimeDrivenBox,
  MaterialLotCostDriverBox,
  MaterialStockLotTimeline,
  MultiUnitProdOrMatBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import aiCoreLogo from '@/assets/images/aicorelogo.png';
import { api } from '@/configs';
import * as actions from '@/redux/actions';
import { findParentAndChildLot } from '@/utils/functions';

/**
 * @function CostDriverDisplay
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function CostDriverDisplay({ title, subtitle }) {
  const dispatch = useDispatch();
  const productStockLot = useSelector((state) => state.productStockLot);
  const history = useHistory();
  const params = useParams();
  const [previousLotList, setPreviousLotList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.productStockLotGet(params.id));
    } catch (error) {
      dispatch(actions.productStockLotError());
    }
  };

  const getPreviousLots = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/product-stock-lot/with-previous-lot/${params.id}`,
      );
      const tempPreviousLot = data?.rows;
      const uniqueLots = _.uniqBy(tempPreviousLot, '_id');
      const tempOrderedLot = _.orderBy(uniqueLots, 'createdAt');
      setPreviousLotList(tempOrderedLot);
    } catch (error) {
      console.error('Cannot Get Previous Lot', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    getPreviousLots();
    return () => {};
  }, [params]);

  const parentWithChildLots = findParentAndChildLot(previousLotList);

  // console.log('Parent Lot', parentLot);
  console.log('Parent With Lot List', parentWithChildLots);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (productStockLot.isLoading) {
    return <Loading />;
  }

  if (!productStockLot.isLoading && productStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <div>
            {isLoading ? (
              <LoadingLinear />
            ) : (
              <div>
                <div className="flex flex-wrap ">
                  <div className="w-full md:w-1/2 lg:w-1/3 pr-2">
                    <Card>
                      <CardContent>
                        {_.map(parentWithChildLots, (eachParentLot, index) => (
                          <div>
                            <div className="text-center">
                              <div className="font-semibold">
                                ต้นทางที่ {index + 1}
                              </div>
                              <div>
                                {eachParentLot?.material?.type_code}{' '}
                                {eachParentLot?.material?.name}
                              </div>
                              <div className="text-sm">
                                หมายเลขอ้างอิงล็อต{' '}
                                {eachParentLot?.running_number}{' '}
                                {eachParentLot?.lot_number &&
                                  `หมายเลขล็อต ${
                                    eachParentLot?.lot_number || ''
                                  }`}{' '}
                              </div>
                            </div>
                            <MaterialStockLotTimeline
                              previousLots={[
                                eachParentLot,
                                ...(eachParentLot.child_lot || []),
                              ]}
                            />
                          </div>
                        ))}
                      </CardContent>
                    </Card>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-2/3">
                    <div className="mb-2">
                      <Card>
                        <CardContent>
                          สินค้าปัจจุบัน
                          <div className="text-xl font-semibold">
                            {productStockLot?.product?.type_code}{' '}
                            {productStockLot.product?.name}
                          </div>
                          <div className="flex justify-between flex-wrap my-2">
                            <div className="w-full md:w-1/2 lg:w-1/4">
                              <div>ปริมาณทั้งหมด</div>
                              <MultiUnitProdOrMatBox
                                foundMaterial={productStockLot?.product}
                                quantity={productStockLot?.quantity}
                              />
                            </div>
                            <div className="w-full md:w-1/2 lg:w-1/4">
                              <div>คงเหลือ</div>
                              <MultiUnitProdOrMatBox
                                foundMaterial={productStockLot?.product}
                                quantity={productStockLot?.amount}
                              />
                            </div>
                          </div>
                          {productStockLot?.product?.net && (
                            <div className="flex justify-between flex-wrap my-2">
                              <div className="w-full md:w-1/2 lg:w-1/4">
                                <div>
                                  ปริมาตรทั้งหมดเป็น
                                  {productStockLot?.product?.unit_net}{' '}
                                </div>
                                <MultiUnitProdOrMatBox
                                  foundMaterial={{
                                    unit: productStockLot?.product?.unit_net,
                                  }}
                                  quantity={
                                    productStockLot?.quantity *
                                    productStockLot?.product?.net
                                  }
                                />
                              </div>
                              <div className="w-full md:w-1/2 lg:w-1/4">
                                <div>คงเหลือ</div>
                                <MultiUnitProdOrMatBox
                                  foundMaterial={{
                                    unit: productStockLot?.product?.unit_net,
                                  }}
                                  quantity={
                                    productStockLot?.amount *
                                    productStockLot?.product?.net
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div>
                            <div className="font-semibold flex justify-between items-center">
                              ต้นทุนของสินค้า (จากการกรอกข้อมูล){' '}
                              <Button
                                variant="contained"
                                size="small"
                                color="warning"
                                startIcon={<i className="fas fa-edit" />}
                              >
                                แก้ไข
                              </Button>
                            </div>
                            <div className="flex justify-between flex-wrap my-2">
                              <div className="w-full md:w-1/2 lg:w-1/4">
                                <div>ต้นทุนทั้งหมด</div>
                                {parseFloat(
                                  productStockLot?.cost_price,
                                ).toFixed(2)}{' '}
                                บาท
                              </div>
                              <div className="w-full md:w-1/2 lg:w-1/4">
                                <div>ต้นทุนต่อหน่วย</div>
                                {parseFloat(
                                  productStockLot?.cost_price /
                                    productStockLot?.quantity,
                                ).toFixed(2)}{' '}
                                บาท
                              </div>
                            </div>
                          </div>{' '}
                          <div className="mt-4">
                            <div className="flex justify-between items-center">
                              <div className="flex gap-2 font-semibold items-center">
                                <div>ต้นทุนโดยประมาณ</div>
                                <div>
                                  <img src={aiCoreLogo} className="h-4" />
                                </div>
                              </div>
                              <Link
                                to={`/wms/cost-driver/how-ai-work/${params.id}`}
                              >
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="info"
                                  startIcon={
                                    <i className="fas fa-info-circle" />
                                  }
                                >
                                  เราใช้ AI อย่างไร
                                </Button>
                              </Link>
                            </div>
                            <div className="flex justify-between flex-wrap my-2">
                              <div className="w-full md:w-1/2 lg:w-1/4">
                                <div>ต้นทุนทั้งหมด</div>- บาท
                              </div>
                              <div className="w-full md:w-1/2 lg:w-1/4">
                                <div>ต้นทุนต่อหน่วย</div>- บาท
                              </div>
                            </div>
                          </div>
                        </CardContent>{' '}
                      </Card>
                    </div>
                    <div className="my-2">
                      <Card>
                        <CardContent>
                          <EmployeeTimeDrivenBox
                            previousLotList={previousLotList}
                            defaultSplittedParent={true}
                          />
                        </CardContent>{' '}
                      </Card>
                    </div>{' '}
                    <div className="my-2">
                      <Card>
                        <CardContent>
                          <MachineTimeDrivenBox
                            previousLotList={previousLotList}
                            defaultSplittedParent={true}
                          />
                        </CardContent>{' '}
                      </Card>
                    </div>
                    <div className="my-2">
                      <Card>
                        <CardContent>
                          <MaterialLotCostDriverBox
                            previousLotList={previousLotList}
                            currentLot={productStockLot}
                            parentWithChildLots={parentWithChildLots}
                          />
                        </CardContent>{' '}
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

CostDriverDisplay.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CostDriverDisplay.defaultProps = {
  title: '',
  subtitle: '',
};
