import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  Error,
  LoadingLinear,
  ManyMaterialLotEditableTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Button } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function EditMaterialCost
 * @description Display a collections or a list of MaterialCosts from database
 */

export default function EditMaterialCost({ title, subtitle }) {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);

  const [isLoading, setIsLoading] = useState(false);
  const { control, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      material_stock_lot: materialStockLot.rows,
    },
  });

  const queryDataFromServer = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        actions.materialStockLotAll({
          page: query.get('page') || 1,
          size: query.get('size') || 25,
          place: query.get('place') || '',
          supplier: query.get('supplier') || '',
          source: query.get('source') || '',
          stockStatus: query.get('stockStatus') || '',
          material: query.get('material') || '',
        }),
      );
      setIsLoading(false);
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  useEffect(() => {
    if (!_.isEmpty(materialStockLot.rows)) {
      setValue('material_stock_lot', materialStockLot.rows);
    }
    return () => {};
  }, [materialStockLot]);

  const onEditMaterialCost = async (data) => {
    const editMaterialPayload = _.map(data.material_stock_lot, (item) => ({
      _id: item._id,
      price: item.price,
    }));

    console.log('editMaterialPayload', editMaterialPayload);

    try {
      await dispatch(actions.materialStockLotPut('many', editMaterialPayload));
      Swal.fire({
        icon: 'success',
        title: 'บันทึกข้อมูลสำเร็จ',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'บันทึกข้อมูลไม่สำเร็จ',
        showConfirmButton: false,
      });
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (!materialStockLot.isLoading && !materialStockLot.isCompleted) {
    return <Error />;
  }

  return (
    <div>
      <div className="flex justify-between items-center">{renderTitle()}</div>
      <BackButton />
      <div className="my-2"></div>
      {materialStockLot.isLoading || isLoading ? (
        <LoadingLinear />
      ) : (
        <div className="my-2">
          <ManyMaterialLotEditableTable
            materialStockLotRows={materialStockLot.rows}
            page={query.get('page')}
            total={materialStockLot.total}
            size={query.get('size')}
            information={information}
            control={control}
            setValue={setValue}
            watch={watch}
          />
        </div>
      )}
      <div className="flex justify-end">
        <Button variant="contained" onClick={handleSubmit(onEditMaterialCost)}>
          บันทึก
        </Button>
      </div>
    </div>
  );
}

EditMaterialCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaterialCost.defaultProps = {
  title: '',
  subtitle: '',
};
