import React, { useState } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { Button, Switch } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import DesktopDatepicker from './DesktopDatepicker';

const DaySpotAndRangePicker = ({
  startDate,
  endDate,
  setStartDate = () => {},
  setEndDate = () => {},
  flex = false,
  size = 'small',
  state = 'date',
  addSearchQuery = false,
  anotherQuery = '',
  defaultUseRange = false,
  enable = true,
}) => {
  const [isUseRange, setIsUseRange] = useState(defaultUseRange);
  const [insideStartDate, setInsideStartDate] = useState(startDate);
  const [insideEndDate, setInsideEndDate] = useState(endDate);
  const [insideDate, setInsideDate] = useState(startDate);
  const history = useHistory();

  const changeMainStateForRange = (newStartDate, newEndDate) => {
    const newStart = dayjs(newStartDate).startOf('date').toDate();
    const newEnd = dayjs(newEndDate).endOf('date').toDate();
    setStartDate(newStart);
    setEndDate(newEnd);
  };

  const changeMainStateForNonRange = (newDate) => {
    setStartDate(dayjs(newDate).startOf(state).toDate());
    setEndDate(dayjs(newDate).endOf(state).toDate());
  };

  const stateInfo = {
    date: {
      type_code: 'date',
      thai_name: 'วัน',
      views: ['year', 'month', 'day'],
      dayjsAddingWord: 'day',
      displayFormat: 'DD/MM/YYYY',
    },
    month: {
      type_code: 'month',
      thai_name: 'เดือน',
      views: ['month', 'year'],
      dayjsAddingWord: 'month',
      displayFormat: 'MMMM YYYY',
    },
  };

  return (
    <div>
      <div className={` ${flex ? 'md:flex' : ''}`}>
        <div className="flex items-center gap-2 w-full">
          <div>จุดเวลา</div>
          <Switch
            disabled={!enable}
            onChange={(event) => {
              setIsUseRange(event.target.checked);
              if (addSearchQuery) {
                history.push(
                  `${anotherQuery}&isUseRange=${
                    event.target.checked === true ? 'true' : ''
                  }`,
                );
              }
            }}
            checked={isUseRange}
          />
          <div>ช่วงเวลา</div>
        </div>
        <div className={'w-full mx-2'}>
          {isUseRange ? (
            <div className="flex gap-2">
              <DesktopDatepicker
                disabled={!enable}
                label="เริ่มต้น"
                size={size}
                onChange={(newValue) => {
                  setInsideStartDate(newValue);
                  changeMainStateForRange(newValue, insideEndDate);
                  if (addSearchQuery) {
                    history.push(
                      `${anotherQuery}&isUseRange=${
                        isUseRange === true ? 'true' : ''
                      }&start=${dayjs(newValue).format(
                        'YYYY-MM-DD',
                      )}&end=${dayjs(insideEndDate).format('YYYY-MM-DD')}`,
                    );
                  }
                }}
                value={insideStartDate}
              />{' '}
              <DesktopDatepicker
                label="สิ้นสุด"
                size={size}
                disabled={!enable}
                onChange={(newValue) => {
                  setInsideEndDate(newValue);
                  changeMainStateForRange(insideStartDate, newValue);
                  if (addSearchQuery) {
                    history.push(
                      `${anotherQuery}&isUseRange=${
                        isUseRange === true ? 'true' : ''
                      }&start=${dayjs(insideStartDate).format(
                        'YYYY-MM-DD',
                      )}&end=${dayjs(newValue).format('YYYY-MM-DD')}`,
                    );
                  }
                }}
                value={insideEndDate}
                inputFormat={stateInfo[state]?.displayFormat}
              />
            </div>
          ) : (
            <div className="my-2 ">
              <DesktopDatepicker
                label="เลือกวันที่ต้องการ"
                size={size}
                disabled={!enable}
                views={stateInfo[state]?.views}
                onChange={(newValue) => {
                  setInsideDate(newValue);
                  changeMainStateForNonRange(newValue);
                  if (addSearchQuery) {
                    history.push(
                      `${anotherQuery}&isUseRange=${
                        isUseRange === true ? 'true' : ''
                      }&start=${dayjs(newValue).format(
                        'YYYY-MM-DD',
                      )}&end=${dayjs(newValue).format('YYYY-MM-DD')}`,
                    );
                  }
                }}
                value={insideDate}
                fullWidth
                inputFormat={stateInfo[state]?.displayFormat}
              />
            </div>
          )}
        </div>
        {!isUseRange && (
          <div className="w-full flex gap-2 items-center">
            <div>
              <Button
                variant="outlined"
                startIcon={<ArrowLeft />}
                onClick={() => {
                  const newDay = dayjs(insideDate).subtract(
                    1,
                    stateInfo[state]?.dayjsAddingWord,
                  );
                  setInsideDate(newDay);
                  changeMainStateForNonRange(newDay);
                  if (addSearchQuery) {
                    history.push(
                      `${anotherQuery}&isUseRange=${
                        isUseRange === true ? 'true' : ''
                      }&start=${dayjs(newDay).format('YYYY-MM-DD')}&end=${dayjs(
                        newDay,
                      ).format('YYYY-MM-DD')}`,
                    );
                  }
                }}
              >
                {stateInfo?.[state]?.thai_name}ก่อนหน้า
              </Button>
            </div>
            <div>
              <Button
                variant="outlined"
                endIcon={<ArrowRight />}
                onClick={() => {
                  const newDay = dayjs(insideDate).add(
                    1,
                    stateInfo[state]?.dayjsAddingWord,
                  );
                  setInsideDate(newDay);
                  changeMainStateForNonRange(newDay);
                  if (addSearchQuery) {
                    history.push(
                      `${anotherQuery}&isUseRange=${
                        isUseRange === true ? 'true' : ''
                      }&start=${dayjs(newDay).format('YYYY-MM-DD')}&end=${dayjs(
                        newDay,
                      ).format('YYYY-MM-DD')}`,
                    );
                  }
                }}
              >
                {stateInfo[state]?.thai_name}ถัดไป
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DaySpotAndRangePicker;

DaySpotAndRangePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  /** ให้มันอยู่บรรทัดเดียวกันมั้ย */
  flex: PropTypes.bool,
  /** Size ของ Datepicker small หรือ medium */
  size: PropTypes.string,
  /** ใช้สำหรับเลือกว่าจะให้เป็นวัน หรือเดือน เป็นค่า date หรือ month เท่านั้น */
  state: PropTypes.string,
  addSearchQuery: PropTypes.bool,
  anotherQuery: PropTypes.string,
  defaultUseRange: PropTypes.bool,
};
