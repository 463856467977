import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  CardInfoProductStockLot,
  CardMaterialStockLotListInPlace,
  Error,
  Loading,
  LoadingLinear,
  MaterialStockLotTimeline,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api } from '@/configs';
import * as actions from '@/redux/actions';
import { findParentAndChildLot } from '@/utils/functions';

/**
 * @function DetailMaterialStockLot
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function DetailProductStockLot({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const productStockLot = useSelector((state) => state.productStockLot);
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);

  const [previousLotList, setPreviousLotList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.productStockLotGet(params.id));
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  const getPreviousLots = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/product-stock-lot/with-previous-lot/${params.id}`,
      );
      const tempPreviousLot = data?.rows;
      const uniqueLots = _.uniqBy(tempPreviousLot, '_id');
      const orderedLot = _.orderBy(uniqueLots, 'createdAt');
      // console.log('Unique Lots', orderedLot);
      setPreviousLotList(orderedLot);
      console.log('Previos Lot', orderedLot);
    } catch (error) {
      console.error('Cannot Get Previous Lot', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    getPreviousLots();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const parentWithChildLots = findParentAndChildLot(previousLotList);

  if (productStockLot.isLoading) {
    return <Loading />;
  }

  if (!productStockLot.isLoading && productStockLot.isCompleted) {
    return (
      <div>
        <div className="flex justify-between items-center">
          {renderTitle()}
          <div className="flex items-center gap-1">
            <Link
              to={
                me?.userData?._id === productStockLot?.employee ||
                me?.userData?.role?.level >= 1
                  ? `/ims/material-stock/edit/${productStockLot?.id}`
                  : '#'
              }
            >
              <Button
                size="small"
                variant="contained"
                color="warning"
                fullWidth
                disabled={
                  !(
                    me?.userData?._id === productStockLot?.employee ||
                    me?.userData?.role?.level >= 1
                  )
                }
              >
                แก้ไข
              </Button>
            </Link>{' '}
            <Link to={`/wms/cost-driver/${productStockLot?.id}`}>
              <Button size="small" variant="contained" color="info">
                ส่วนประกอบต้นทุน
              </Button>
            </Link>
          </div>
        </div>
        <BackButton />
        <div className="my-2">
          <CardInfoProductStockLot
            productStockLot={productStockLot}
            information={information}
          />
        </div>
        {isLoading && <LoadingLinear />}
        {!_.isEmpty(previousLotList) && (
          <div className="my-2">
            <div className="font-semibold my-2 text-lg">ประวัติล็อต</div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-3/5 pr-2">
                <Card>
                  <CardContent>
                    {_.map(parentWithChildLots, (eachParentLot, index) => (
                      <div>
                        <div className="font-semibold">
                          ต้นทางที่ {index + 1}
                        </div>
                        <div>
                          {eachParentLot?.material?.type_code}{' '}
                          {eachParentLot?.material?.name}
                        </div>
                        <div className="text-sm">
                          หมายเลขอ้างอิงล็อต {eachParentLot?.running_number}{' '}
                          {eachParentLot?.lot_number &&
                            `หมายเลขล็อต ${
                              eachParentLot?.lot_number || ''
                            }`}{' '}
                        </div>
                        <MaterialStockLotTimeline
                          previousLots={[
                            eachParentLot,
                            ...(eachParentLot.child_lot || []),
                          ]}
                        />
                      </div>
                    ))}
                  </CardContent>
                </Card>
              </div>
              <div className="w-full lg:w-2/5">
                <CardMaterialStockLotListInPlace
                  previousLots={previousLotList}
                  currentLot={productStockLot}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  return <Error />;
}

DetailProductStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProductStockLot.defaultProps = {
  title: '',
  subtitle: '',
};
