import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  EmployeeAutoComplete,
  Error,
  Loading,
  MaterialStockTypeAddForm,
  MultiUnitProdOrMatBox,
  ProdOrMatQuantityInput,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Alert,
  Button,
  Card,
  CardContent,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function EditMaterialStockLot
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function EditMaterialStockLot({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const employee = useSelector((state) => state.employee);
  const history = useHistory();
  const params = useParams();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      employee: materialStockLot?.employee,
    },
  });

  const filterGroupEmployee = _.filter(
    employee?.rows,
    (each) => each?.is_virtual_group_employee,
  );

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.materialStockLotGet(params.id));
      dispatch(
        actions.employeeAll({
          name: '',
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleEdit = async (data) => {
    try {
      const anotherEmployees = _.map(data?.another_employees, (each) => ({
        employee: each?.employee?._id,
        amount: parseFloat(each?.amount),
      }));
      const filterAnotherEmployees = _.filter(
        anotherEmployees,
        (each) => each?.amount > 0,
      );

      dispatch(
        actions.materialStockLotPut(params.id, {
          ...data,
          recipient_name: `${data?.employee?.firstname} ${
            data?.employee?.lastname || ''
          }`,
          another_employees: filterAnotherEmployees,
        }),
      );
      history.goBack();
    } catch (error) {
      alert(`ไม่สามารถแก้ไขได้ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialStockLot.isLoading || materialStockLot?.rows) {
    return <Loading />;
  }

  if (
    !materialStockLot.isLoading &&
    materialStockLot.isCompleted &&
    !materialStockLot?.rows
  ) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleEdit)}>
            <Card>
              <CardContent>
                <MaterialStockTypeAddForm
                  control={control}
                  errors={errors}
                  information={information}
                  me={me}
                  materialStockLot={materialStockLot}
                  employee={employee}
                  watch={watch}
                />
                <hr className="my-2" />
                <div className="p-2">
                  <div className="font-semibold">วัตถุดิบ</div>
                  <div className="text-lg mb-2">
                    {materialStockLot?.material?.type_code}{' '}
                    {materialStockLot?.material?.name}
                  </div>
                  <div className="flex flex-wrap justify-between items-center">
                    <div className="w-full md:w-1/2"> ปริมาณทั้งหมด </div>
                    <div className="flex gap-2 w-full md:w-1/2 justify-end">
                      <MultiUnitProdOrMatBox
                        foundMaterial={materialStockLot?.material}
                        quantity={materialStockLot?.quantity}
                      />
                    </div>
                    <div className="w-full md:w-1/2 my-2">ปริมาณใหม่</div>
                    <div className="w-full md:w-1/2 justify-end my-2">
                      <ProdOrMatQuantityInput
                        controllerName="quantity"
                        artificialControllerName="quantity_artificial"
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        defaultValue={materialStockLot?.quantity}
                        selectedProduct={materialStockLot?.material}
                        selectedUnitControllerName="quantity_selected_unit"
                      />
                    </div>{' '}
                    <div className="w-full md:w-1/2"> คงเหลือเดิม </div>
                    <div className="flex gap-2 w-full md:w-1/2 justify-end">
                      <MultiUnitProdOrMatBox
                        foundMaterial={materialStockLot?.material}
                        quantity={materialStockLot?.amount}
                      />
                    </div>
                    <div className="w-full md:w-1/2 my-2">คงเหลือใหม่</div>
                    <div className="w-full md:w-1/2 justify-end my-2">
                      <ProdOrMatQuantityInput
                        controllerName="amount"
                        artificialControllerName="amount_artificial"
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        defaultValue={materialStockLot?.amount}
                        selectedProduct={materialStockLot?.material}
                        selectedUnitControllerName="amount_selected_unit"
                      />
                    </div>
                  </div>
                  {parseFloat(watch('quantity')) <
                    parseFloat(watch('amount')) && (
                    <Alert severity="error">
                      ปริมาณทั้งหมดไม่ควรน้อยกว่าคงเหลือ
                    </Alert>
                  )}
                </div>
                <hr className="my-2" />
                <div className="flex flex-wrap justify-between items-center">
                  <div className="w-full md:w-1/2"> ราคาทั้งล็อต </div>
                  <div className="flex flex-wrap gap-2 w-full md:w-1/2 justify-end">
                    <Controller
                      control={control}
                      name="price_per_unit"
                      defaultValue={
                        materialStockLot?.price / materialStockLot?.quantity
                      }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ต้นทุนต่อหน่วย"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            field.onChange(e);
                            setValue(
                              'price',
                              parseFloat(e.target.value) * watch('quantity'),
                            );
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="price"
                      defaultValue={materialStockLot?.price}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ต้นทุนทั้งล็อต (บาท)"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            field.onChange(e);
                            setValue(
                              'price_per_unit',
                              parseFloat(e.target.value) / watch('quantity'),
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full md:w-1/2 my-1">
                    ผู้ที่ดำเนินการกับล็อต / ผู้รับวัตถุดิบ{' '}
                  </div>
                  <div className="w-full md:w-1/2 my-1 justify-end">
                    {!_.isEmpty(employee?.rows) && (
                      <Controller
                        control={control}
                        name="employee"
                        defaultValue={materialStockLot?.employee}
                        render={({ field }) => (
                          <EmployeeAutoComplete
                            employeeRows={employee?.rows}
                            field={field}
                            label="ผู้ดำเนินการ"
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap w-full">
                  {information?.setting?.ims
                    ?.showGroupEmployeeInputInSpreadWay && (
                    <div className="w-full lg:w-2/3 px-1">
                      <div className="my-2 pb-2">พนักงานที่ร่วมทำงาน</div>
                      {_.map(filterGroupEmployee, (each, index) => (
                        <div className="flex gap-2 w-full py-2">
                          <div className="w-2/3">
                            <Controller
                              key={index}
                              name={`another_employees[${index}].employee`}
                              control={control}
                              defaultValue={
                                materialStockLot?.another_employees?.[index]
                                  ?.employee
                              }
                              render={({ field }) => (
                                <EmployeeAutoComplete
                                  field={field}
                                  employeeRows={employee?.rows}
                                  label="ผู้ดำเนินการ"
                                />
                              )}
                            />
                          </div>
                          <div className="w-1/3">
                            <Controller
                              key={index}
                              name={`another_employees[${index}].amount`}
                              control={control}
                              defaultValue={
                                materialStockLot?.another_employees?.[index]
                                  ?.amount
                              }
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  label="จำนวน"
                                  fullWidth
                                  size={'small'}
                                  type="number"
                                />
                              )}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>{' '}
            <div className="flex justify-end my-1">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditMaterialStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaterialStockLot.defaultProps = {
  title: '',
  subtitle: '',
};
