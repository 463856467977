import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Error,
  LoadingLinear,
  MaterialCostTable,
  MaterialStockFilterBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

import { config } from '../../configs';

/**
 * @function MaterialCosts
 * @description Display a collections or a list of MaterialCosts from database
 */

export default function MaterialCosts({ title, subtitle }) {
  const dispatch = useDispatch();
  const query = useQuery();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const customer = useSelector((state) => state.customer);
  const materialSource = useSelector((state) => state.materialSource);
  const [page, setPage] = useState(query.get('page') || 1);
  const [size, setSize] = useState(query.get('size') || 25);
  const [selectedPlace, setSlectedPlace] = useState(
    query.get('place') ? { _id: query.get('place') } : null,
  );
  const [selectedSupplier, setSelectedSupplier] = useState(
    query.get('supplier') ? { _id: query.get('supplier') } : null,
  );
  const [selectedSource, setSelectedSource] = useState(
    query.get('source') ? { _id: query.get('source') } : null,
  );
  const [selectedStockStatus, setSelectedStockStatus] = useState(
    query.get('stockStatus') ? { _id: query.get('stockStatus') } : null,
  );
  const [selectedMaterial, setSelectedMaterial] = useState(
    query.get('material') ? { _id: query.get('material') } : null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const queryDataFromServer = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        actions.materialStockLotAll({
          page,
          size,
          place: selectedPlace?._id || '',
          supplier: selectedSupplier?._id || '',
          source: selectedSource?._id || '',
          stockStatus: selectedStockStatus || '',
          material: selectedMaterial?._id || '',
        }),
      );
      setIsLoading(false);
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  useEffect(() => {
    dispatch(
      actions.placeAll({
        page: 1,
        size: config.maxFetchSize,
      }),
    );

    dispatch(
      actions.customerAll({
        page: 1,
        size: config.maxFetchSize,
      }),
    );

    dispatch(
      actions.materialSourceAll({
        page: 1,
        size: config.max,
      }),
    );

    dispatch(
      actions.materialAll({
        page: 1,
        size: config.max,
      }),
    );

    dispatch(
      actions.materialSourceAll({
        page: 1,
        size: config.max,
      }),
    );

    return () => {};
  }, []);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [
    page,
    size,
    selectedPlace,
    selectedSupplier,
    selectedSource,
    selectedStockStatus,
    selectedMaterial,
  ]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (!materialStockLot.isLoading && !materialStockLot.isCompleted) {
    return <Error />;
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        {renderTitle()}
        <div className="flex gap-2">
          <Link to={'/ims/material-cost/daily'}>
            <Button variant="contained" color="info">
              ต้นทุนวัตถุดิบรายวัน
            </Button>
          </Link>{' '}
          <Link to={`/ims/material-cost/edit?${query.toString()}`}>
            <Button variant="contained" color="warning">
              แก้ไข
            </Button>
          </Link>
        </div>
      </div>

      <div className="my-2">
        <MaterialStockFilterBox
          customer={customer}
          material={material}
          place={place}
          source={materialSource}
          selectedMaterial={selectedMaterial}
          selectedPlace={selectedPlace}
          selectedSource={selectedSource}
          selectedSupplier={selectedSupplier}
          selectedStockStatus={selectedStockStatus}
          setSelectedMaterial={setSelectedMaterial}
          setSelectedPlace={setSlectedPlace}
          setSelectedSource={setSelectedSource}
          setSelectedSupplier={setSelectedSupplier}
          setSelectedStockStatus={setSelectedStockStatus}
          allowAddToSeachQuery={true}
          anotherQuery={`?page=${page}&size=${size}`}
        />
      </div>
      {materialStockLot.isLoading || isLoading ? (
        <LoadingLinear />
      ) : (
        <div className="my-2">
          <MaterialCostTable
            materialStockLotRows={materialStockLot.rows}
            page={page}
            setPage={setPage}
            setSize={setSize}
            total={materialStockLot.total}
            size={size}
            information={information}
            allowAddToSeachQuery={true}
            anotherQuery={`?place=${selectedPlace?._id || ''}&material=${
              selectedMaterial?._id || ''
            }&source=${selectedSource?._id || ''}&supplier=${
              selectedSupplier?._id || ''
            }&stockStatus=${selectedStockStatus || ''}`}
          />
        </div>
      )}
    </div>
  );
}

MaterialCosts.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialCosts.defaultProps = {
  title: '',
  subtitle: '',
};
