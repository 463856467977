import React, { useEffect, useState } from 'react';
import { INVENTORY_WORKFLOW } from '@iarcpsu/emanufac-constant';
import { Chip } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getEmployeePayrollUnitCostOnTimeWithAPI } from '@/utils/functions';

import NameBox from '../Common/NameBox';

const EmployeeOnTimePairDisplay = ({
  selectedEmployee,
  timeUsage,
  foundWorkType,
  timePair,
  representStartLot,
  representEndLot,
  isAnotherEmployee = false,
  amountOfEmployee,
  information,
}) => {
  const [unitCost, setUnitCost] = useState(0);

  const getEmployeePayrollAdjustment = async () => {
    try {
      const foundedUnitCost = await getEmployeePayrollUnitCostOnTimeWithAPI({
        representEmployee: selectedEmployee,
        timePair,
        information,
      });
      // console.log('Founded Unit Cost', foundedUnitCost);
      setUnitCost(foundedUnitCost);
    } catch (error) {
      console.error('Cannot Get Employee Payroll Adjustment', error);
    }
  };

  useEffect(() => {
    getEmployeePayrollAdjustment();

    return () => {};
  }, []);

  return (
    <div>
      <div className="flex gap-2">
        <div className="flex gap-2 items-center">
          <NameBox user={selectedEmployee} showVirtualGroup />
          {isAnotherEmployee && selectedEmployee?.is_virtual_group_employee && (
            <div>x จำนวน {amountOfEmployee} คน</div>
          )}
        </div>
      </div>
      {!isAnotherEmployee && (
        <div>
          ช่วงเวลา {dayjs(timePair?.[0]).format('D MMM HH:mm')} -{' '}
          {dayjs(timePair?.[1]).format('D MMM HH:mm')} ใช้เวลา {timeUsage} นาที
        </div>
      )}
      <div>
        ค่าแรงโดยประมาณ {unitCost?.toFixed(2)} บาท/นาที รวมเป็น{' '}
        <span className="font-semibold">
          {(unitCost * timeUsage)?.toFixed(2)}{' '}
          {isAnotherEmployee && selectedEmployee?.is_virtual_group_employee && (
            <span>
              <span className="text-blue-600"> x {amountOfEmployee}</span> =
              {(unitCost * timeUsage * amountOfEmployee)?.toFixed(2)}
            </span>
          )}
        </span>{' '}
        บาท
      </div>
      {!isAnotherEmployee && (
        <div className="text-sm flex gap-2 items-center my-2">
          {_.isEqual(
            representStartLot?.place?._id,
            representEndLot?.place?._id,
          ) ? (
            <Chip size="small" label={representStartLot?.place?.name}></Chip>
          ) : (
            <Chip
              size="small"
              label={`${representStartLot?.place?.name}-
        ${representEndLot?.place?.name || representEndLot?.warehouse?.name}`}
            ></Chip>
          )}
          {INVENTORY_WORKFLOW[foundWorkType]?.description}
        </div>
      )}
    </div>
  );
};

export default EmployeeOnTimePairDisplay;

EmployeeOnTimePairDisplay.propTypes = {
  selectedEmployee: PropTypes.object,
  timeUsage: PropTypes.number,
  foundWorkType: PropTypes.string,
  timePair: PropTypes.array,
  representStartLot: PropTypes.object,
  representEndLot: PropTypes.object,
  isAnotherEmployee: PropTypes.bool,
  amountOfEmployee: PropTypes.number,
  information: PropTypes.object,
};
