import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { MATERIAL_STOCK_STATUS } from '@iarcpsu/emanufac-constant';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';
import { currencyFormatter } from '@/utils/functions';

export default function MaterialCostTable({
  page,
  size,
  setPage,
  setSize,
  total,
  materialStockLotRows,
  information,
  allowAddToSeachQuery,
  anotherQuery = '?',
}) {
  const imsSetting = information?.setting?.ims;
  const { findWord } = useContext(WordLocalizeContext);
  const history = useHistory();

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">
                  {findWord('recipt_date') || 'วันที่รับวัตถุดิบ'}
                </div>
              </TableCell>
              {imsSetting?.display?.purchase_date && (
                <TableCell>
                  <div className="font-bold">
                    {findWord('purchase_date') || 'วันที่ซื้อ'}
                  </div>
                </TableCell>
              )}
              {imsSetting?.display?.lot_number && (
                <TableCell>
                  <div className="font-bold">หมายเลขล็อต</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold">หมายเลขอ้างอิงล็อต</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">วัตถุดิบ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">คู่ค้า</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">สถานะ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ต้นทุนต่อหน่วย</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ต้นทุนทั้งล็อต</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(materialStockLotRows) ? (
              _.map(materialStockLotRows, (row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell>{(page - 1) * size + index + 1}</TableCell>
                  <TableCell>
                    {dayjs(row?.receipt_date).format('D MMM BBBB')}
                  </TableCell>
                  {imsSetting?.display?.purchase_date && (
                    <TableCell>
                      {dayjs(row?.purchase_date).format('D MMM BBBB')}
                    </TableCell>
                  )}
                  {imsSetting?.display?.lot_number && (
                    <TableCell>{row?.lot_number}</TableCell>
                  )}
                  <TableCell>{row?.running_number}</TableCell>
                  <TableCell>
                    {row?.material?.type_code} {row?.material?.name}
                  </TableCell>
                  <TableCell>
                    {row?.supplier?.name}
                    {row?.source && <div>{row?.source?.name}</div>}
                  </TableCell>
                  <TableCell>
                    {MATERIAL_STOCK_STATUS[row?.status].description}
                  </TableCell>
                  <TableCell>
                    {row?.price
                      ? currencyFormatter.format(row?.price / row?.quantity)
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {row?.price ? currencyFormatter.format(row?.price) : '-'}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        page={page - 1}
        count={total || 1}
        rowsPerPage={size}
        onRowsPerPageChange={(event) => {
          setSize(event.target.value);
          setPage(1);
          if (allowAddToSeachQuery) {
            history.push(`${anotherQuery}&size=${event.target.value}&page=1`);
          }
        }}
        onPageChange={(event, newValue) => {
          setPage(newValue + 1);
          if (allowAddToSeachQuery) {
            history.push(`${anotherQuery}&size=${size}&page=${newValue + 1}`);
          }
        }}
      />
    </Paper>
  );
}

MaterialCostTable.propTypes = {
  page: PropTypes.number,
  size: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  total: PropTypes.number,
  materialStockLotRows: PropTypes.arrayOf(PropTypes.object),
  information: PropTypes.object,
  allowAddToSeachQuery: PropTypes.bool,
  anotherQuery: PropTypes.string,
};
